import React from 'react';
import classes from './JogaDoga.module.css';
const PsiePrzedszkole = (props) => {
  return (
    <div className='container'>
      <div className={classes.JogaDogaMain}>
        <br />
        <br></br>
        <h3>JogaDoga – psi fitness dla psów dorosłych</h3>
        <p>Zajęcia psiego fitnessu to treningi, których celem jest poprawa sprawności fizycznej psa, rozwijanie świadomości ciała, przywrócenie prawidłowych wzorców ruchowych, a tym samym minimalizowanie kontuzji. </p>
        <p>Poza korzyściami wynikającymi z zadbania o kondycję psa, psi fitness wpływa również na pogłębienie więzi w duecie pies – człowiek, buduje zaufanie oraz pozwala na przełamanie niektórych lęków np. względem przedmiotów. </p>
        <p>Odpowiednio dobrane ćwiczenia wspomagają również psy z problemem otyłości w procesie odchudzania.</p>
        <p><strong>Kurs dedykowany jest dla psów dorosłych, zdrowych.</strong></p>
        <p><strong>Suczki w cieczce nie mogą uczestniczyć w kursie.</strong></p>
        <br></br>
      </div>
    </div>
  );
};

export default PsiePrzedszkole;
