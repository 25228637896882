import React from 'react';

const ZdolnyPies = (props) => {
  return (
    <div>
      <h3>Klasa 3 - Zdolny Pies</h3>
      <p>Kurs przeznaczony dla absolwentów Klasy 2.</p>
      <p>Pies lubi spędzać czas aktywnie i lubi się uczyć.</p>
      <p>Jeśli nadal chcesz dbać o jego rozwój nie tylko fizyczny, ale również umysłowy zapraszamy do klasy 3.</p>
      <p>W programie:</p>
      <ul>
        <li>wstęp do aportowania</li>
        <li>komendy optyczne i głosowe z dużych odległości</li>
        <li>zatrzymanie psa z ruchu komendą SIAD i WARUJ</li>
        <li>zostawanie na duże odległości</li>
      </ul>
      <p>Zajęcia 2 razy w tygodniu w dni i godziny wyznaczone przy zapisach.</p>
    </div>
  );
};

export default ZdolnyPies;
