import './App.css';
import './assets/styles/style.scss';
import Landing from './containers/landing/landing';
import Banner from './components/Banner/Banner';
import Footer from './components/Footer/Footer';
import OMNIE from './containers/Pages/o_mnie/o_mnie';
import Regulamin from './containers/Pages/Regulamin/Regulamin';
import PsiePrzedszkole from './containers/Pages/PsiePrzedszkole/PsiePrzedszkole';
import NavigationBar from './components/NavigationBar/NavigationBar';
import Nosework from './containers/Pages/Nosework/Nosework';
import ZdolnyPiesWeszyciel from './containers/Pages/ZdolnyPiesWeszyciel/ZdolnyPiesWeszyciel';
import Headroom from 'react-headroom';
import RallyO from './containers/Pages/RallyO/RallyO';
import Posluszenstwo from './containers/Pages/Posluszenstwo/Posluszenstwo';
import PracaWechowa from './containers/Pages/PracaWechowa/PracaWechowa';
import MainPage from './containers/Pages/MainPage/MainPage';
import Behawiorysta from './containers/Pages/Behawiorysta/Behawiorysta';
import JogaDoga from './containers/Pages/JogaDoga/JogaDoga';
import JogaJunior from './containers/Pages/JogaJunior/JogaJunior';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { BiUpArrowCircle } from 'react-icons/bi';

function App() {
  return (
    <div className='container2'>
      <Router>
        <Headroom>
          <NavigationBar></NavigationBar>
        </Headroom>
        <Banner />
        <Switch>
          <div style={{ minHeight: '100px' }}>
            <Route path='/' exact component={(props) => <MainPage {...props} />}></Route>
            <Route path='/o-mnie' exact component={(props) => <OMNIE {...props} />}></Route>
            <Route path='/regulamin' exact component={(props) => <Regulamin {...props} />}></Route>
            <Route path='/psie-przedszkole' exact component={(props) => <PsiePrzedszkole {...props} />}></Route>
            <Route path='/joga-doga' exact component={(props) => <JogaDoga {...props} />}></Route>
            <Route path='/joga-junior' exact component={(props) => <JogaJunior {...props} />}></Route>
            <Route path='/posluszenstwo' exact component={(props) => <Posluszenstwo {...props} />}></Route>
            <Route path='/praca-wechowa' exact component={(props) => <PracaWechowa {...props} />}></Route>
            <Route path='/nosework' exact component={(props) => <Nosework {...props} />}></Route>
            <Route path='/rally-o' exact component={(props) => <RallyO {...props} />}></Route>
            <Route path='/spotkanie-z-behawiorysta' exact component={(props) => <Behawiorysta {...props} />}></Route>

            <Route
              path='/zdolny-pies-weszyciel'
              exact
              component={(props) => <ZdolnyPiesWeszyciel {...props} />}
            ></Route>
          </div>
        </Switch>

        <Footer />
        <ScrollToTop showUnder={160}>
          <span className='scroll-up-span'>
            <BiUpArrowCircle size={'38px'} />
          </span>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
