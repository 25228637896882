import React from "react";
import classes from "./JogaJunior.module.css";
import CardMedia from "@material-ui/core/CardMedia";
import img from "../../../assets/images/media/jogajunior.webp";
const PsiePrzedszkole = props => {
  return (
    <div className="container">
      <div className={classes.JogaJuniorMain}>
        <br />
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="300px"
          image={img}
          title="Contemplative Reptile"
          style={{ objectFit: "contain" }}
        />
        <br />
        <h3>JogaJunior - psi fitness dla szczeniąt</h3>
        <p>
          Gimnastyka malucha jest bardzo ważnym elementem w jego rozwoju.
          Ćwiczenia dla szczeniaka muszą być odpowiednio modyfikowane ze względu
          na jego szybki rozwój, ale praca nad prawidłowym ruchem, świadomością
          ciała, poznawaniem różnych nawierzchni, faktur i kształtów przyniesie
          ogromne korzyści w życiu dorosłym.
        </p>
        <p>
          Ćwiczenia fizyczne przeplatamy zabawami na matach węchowych i
          zabawkach interaktywnych.
        </p>
        <p>
          <strong>
            Kurs przeznaczony jest dla szczeniąt zdrowych, w wieku od 4,5
            miesiąca do 6 miesięcy.
          </strong>
        </p>

        <br />
      </div>
    </div>
  );
};

export default PsiePrzedszkole;
