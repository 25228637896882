import React from 'react';
import classes from './RallyO.module.css';
import { Button } from 'react-bootstrap';
import rallyImg from '../../../assets/images/media/rally.webp';
import CardMedia from '@material-ui/core/CardMedia';
const RallyO = (props) => {
  return (
    <div className='container'>
      <div className={classes.rallyOMain}>
        <br />
        <CardMedia
          component='img'
          alt='Contemplative Reptile'
          height='300px'
          image={rallyImg}
          title='Contemplative Reptile'
        />

        <br />
        <h3>Rally-O</h3>
        <p>
          <b>Rally – obedience (Rally – O)</b> jest dyscypliną dla każdego psa i&nbsp;właściciela. Sport ten polega na
          pokonaniu rajdu złożonego z&nbsp;szeregu ćwiczeń – stacji. Reguły Rally – O&nbsp;nie są tak sztywne jak
          w&nbsp;tradycyjnym Obedience. Przewodnik może chwalić psa, wydawać komendy słownie lub optycznie bądź słownie
          i&nbsp;optycznie o&nbsp;ile regulamin nie stanowi inaczej. Różnorodność Klas i&nbsp;Kategorii jakie przewiduje
          regulamin Rally – O&nbsp;pozwala na start w&nbsp;zawodach przewodnikom i&nbsp;psom w&nbsp;różnym wieku. Dzięki
          temu w&nbsp;zawodach mogą również startować dzieci. Zajęcia Rally – O&nbsp;są świetną zabawą dla psa
          i&nbsp;jego opiekuna. Celem naszych zajęć jest przygotowanie drużyny – psa i&nbsp;przewodnika – do startu
          w&nbsp;zawodach, jednakże nie jest to obowiązkowe. Przyjemność jaką daje psu możliwość wykonywania kolejnych
          ćwiczeń, a&nbsp;przewodnikowi satysfakcja z&nbsp;pokonania kolejnych stacji rajdu same w&nbsp;sobie są nagrodą
          dla drużyny.
        </p>

        <div className={classes.anchorDiv}>
          <Button target='_blank' href='http://www.rally-o.pl/' variant={'outline-dark'}>
            Regulamin Rally-O
          </Button>
        </div>

        <br />
        <h4>
          Do udziału w zajęciach zapraszamy psiaki, <br />
          które znają podstawowe polecenia ( zakres kursu Grzeczny Pies)
        </h4>
        <br></br>
      </div>
    </div>
  );
};

export default RallyO;
