import React from 'react';

const SuperPies = (props) => {
  return (
    <div>
      <h3>Klasa 5 - Super Pies</h3>
      <p>Kurs przeznaczony dla absolwentów Klasy 4.</p>
      <p>Utrwalenie umiejętności nabytych w klasie 3 oraz:</p>
      <ul>
        <li>skok w dal</li>
        <li>noszenie koszyczka</li>
        <li>targetowanie</li>
      </ul>
      <p>Zajęcia 2 razy w tygodniu w dni i godziny wyznaczone przy zapisach.</p>
    </div>
  );
};

export default SuperPies;
