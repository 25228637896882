import React from 'react';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import navLogo from '../../assets/images/nav-logo.webp';
import classes from './NavigationBar.module.css';
import './Navbar.css';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
const NavigationBar = (props) => {
  return (
    <div className={classes.navigationBarMainDiv}>
      <div className={'container'}>
        <Navbar
          collapseOnSelect
          expand='lg'
          bg='light'
          variant='light'
          style={{ border: 'none', fontWeight: '600', fontSize: '18px' }}
        >
          <LinkContainer to='/'>
            <Navbar.Brand>
              <img style={{ height: '50px' }} src={navLogo}></img>
            </Navbar.Brand>
          </LinkContainer>
          <Nav className='mr-auto'>
            <LinkContainer to='/'>
              <Nav.Link className={props.location.pathname === '/' ? classes.isActive : classes.isInactive}>
                Strona główna
              </Nav.Link>
            </LinkContainer>
          </Nav>

          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='mr-auto'></Nav>
            <Nav>
              <LinkContainer to='o-mnie'>
                <Nav.Link className={props.location.pathname === '/o-mnie' ? classes.isActive : classes.isInactive}>
                  O mnie
                </Nav.Link>
              </LinkContainer>

              <NavDropdown
                title={'Rodzaje kursów'}
                id={
                  props.location.pathname === '/psie-przedszkole' ||
                  props.location.pathname === '/posluszenstwo' ||
                  props.location.pathname === '/praca-wechowa' ||
                  props.location.pathname === '/joga-doga' ||
                  props.location.pathname === '/joga-junior' 
                    ? 'is-active'
                    : 'basic-nav-dropdown'
                }
              >
                <LinkContainer to='psie-przedszkole'>
                  <Nav.Link
                    className={props.location.pathname === '/psie-przedszkole' ? classes.isActive : classes.isInactive}
                  >
                    Psie Przedszkole
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer
                  className={props.location.pathname === '/posluszenstwo' ? classes.isActive : classes.isInactive}
                  to='posluszenstwo'
                >
                  <Nav.Link>Posłuszeństwo</Nav.Link>
                </LinkContainer>
                <LinkContainer to='praca-wechowa'>
                  <Nav.Link
                    className={props.location.pathname === '/praca-wechowa' ? classes.isActive : classes.isInactive}
                  >
                    Praca węchowa
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='joga-junior'>
                  <Nav.Link
                    className={props.location.pathname === '/joga-junior' ? classes.isActive : classes.isInactive}
                  >
                    Joga Junior
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to='joga-doga'>
                  <Nav.Link
                    className={props.location.pathname === '/joga-doga' ? classes.isActive : classes.isInactive}
                  >
                    Joga Doga
                  </Nav.Link>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to='rally-o'>
                <Nav.Link className={props.location.pathname === '/rally-o' ? classes.isActive : classes.isInactive}>
                  Rally-O
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to='spotkanie-z-behawiorysta'>
                <Nav.Link
                  className={
                    props.location.pathname === '/spotkanie-z-behawiorysta' ? classes.isActive : classes.isInactive
                  }
                >
                  Spotkanie z behawiorystą
                </Nav.Link>
              </LinkContainer>

              <LinkContainer to='regulamin'>
                <Nav.Link className={props.location.pathname === '/regulamin' ? classes.isActive : classes.isInactive}>
                  Regulamin
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default withRouter(NavigationBar);
