import React from 'react';
import classes from './Nosework.module.css';
const Nosework = (props) => {
  return (
    <div className={'container'}>
      <div className={classes.NoseworkMain}>
        <h3>NOSEWORK</h3>
        <p>
          NOSEWORK to nowa dyscyplina kynologiczna wywodząca się z pracy psów policyjnych wykorzystywanych do wykrywania
          materiałów niebezpiecznych. Nosework powstał z myślą o psach cywilnych, które w domowych warunkach mogą
          również „ pracować nosem”. Dyscyplina ta pozwala każdemu psu, bez względu na rasę, wiek i charakter uprawiać
          ten sport. Ćwiczenia nosework mają przede wszystkim sprawiać psu olbrzymią przyjemność. Wielką zaletą nosework
          dla przewodników jest fakt, że ćwiczymy głównie w domu. W długie, jesienno – zimowe wieczory możemy ćwiczyć i
          bawić się z psem bez konieczności wychodzenia na zewnątrz. Latem możemy przenieść tereny poszukiwań do ogrodu,
          parku itd.
        </p>
        <p>
          Kurs Nosework odbywa się w ogrzewanej salce treningowej. Suczki z cieczką nie mogą uczestniczyć w kursie.
          Sunia w rui jest przenoszona na następny kurs.
        </p>
        <h6>Poziom "0" PODSTAWY NOSEWORK</h6>
        <p>
          Kurs obejmuje naukę rozpoznawania i oznaczania zapachów cynamonu, pomarańczy i goździków. Pracujemy na
          olejkach zapachowych.
        </p>
        <h6>Poziom 1 KATEGORIE: INTERIOR, CONTAINERS, VEHICLE</h6>
        <p>
          W trakcie kursu rozwijamy umiejętności nabyte na poziomie „0”. Wprowadzamy przeszukanie pomieszczeń, samochodu
          i różnego rodzaju przedmiotów.
        </p>
        <h6>Poziom 2 KATEGORIE: EXTERIOR, WATER</h6>
        <p>Wprowadzenie do przeszukań terenów zewnętrznych oraz próbki w wodzie. </p>
        <h6>Poziom 3 WSZYSTKIE KATEGORIE</h6>
        <p>Wprowadzamy próbki wielokrotne i niedostępne. </p>
        <h6>Przygotowanie do egzaminów i zawodów </h6>
        <p>
          To kurs dla zespołów, które chciałby zweryfikować swoje umiejętności przystępując do egzaminów ( dla
          absolwentów poziomu „0” lub biorąc udział w zawodach ( dla absolwentów poziomu 1, 2 i 3). Na treningach
          skupiamy się na pracy wg obowiązujących regulaminów, rutynie startowej oraz handlingu.{' '}
        </p>
      </div>
    </div>
  );
};

export default Nosework;
