import React from 'react';
import classes from './o_mnie.module.css';
import AboutMeGallery from '../../../components/AboutMeGallery/AboutMeGallery';
const o_mnie = (props) => {
  return (
    <div className={'container'}>
      <div className={classes.OMnieMain}>
        <br></br>
        <h3>O mnie</h3>
        <p>
          Nazywam się Eliza Barczewska. Nie będę pisać jak bardzo kocham zwierzęta i&nbsp;że marzyłam o&nbsp;pracy
          z&nbsp;psami od dziecka. Prawda jest taka, że w&nbsp;moim domu pojawił się pies, który wykorzystał całą psią
          inteligencję do okręcenia nas sobie wokół ogona. Uchodziło mu na sucho wszystko: ucieczki na spacerach,
          konsumpcja nowiutkiej kanapy, niezliczonej ilości kurtek i&nbsp;butów, przesadzanie kwiatków na oknie oraz
          zdejmowanie firanek, remont mieszkania ( bo przecież ciekawsze są ściany z&nbsp;olbrzymimi dziurami),
          częstowanie się z&nbsp;piekarnika gorącą pieczenią i&nbsp;wymieniać mogłabym tak jeszcze długo. Pewnego dnia
          psina dojrzała i&nbsp;postanowiła zaprowadzić rządy absolutne na spacerach. Awantury z&nbsp;innymi psami stały
          się podstawową rozrywką mojego Syneczka, a&nbsp;fundowanie sprintu Pańci od drzewa do drzewa ulubioną zabawą.
          Był to moment, w&nbsp;którym postawiono mi ultimatum: my albo pies. Trafiłam na szkolenie do jednej
          z&nbsp;najlepszych szkół w&nbsp;Trójmieście. Tam dopiero otworzono mi oczy, że Synusia trzeba kochać po psiemu
          nie po ludzku, że dyscyplina, że konsekwencja, że ton głosu niepotrzebnie podniesiony, że nerwy niepotrzebne,
          że kompletnie nie rozumiem dlaczego on to wszystko robi w&nbsp;związku z&nbsp;czym to moja wina. Mam takiego
          psa jakiego sobie wychowałam, a&nbsp;raczej nie wychowałam... i&nbsp;zaczęło się nie tyle szkolenie co
          wychowanie mnie przede wszystkim. Dwa lata pracy zajęło mi naprawianie swoich błędów. Dotarło do mnie, że jego
          zachowanie jest spowodowane moją niewiedzą i&nbsp;brakiem zrozumienia. Widząc efekty mojej pracy
          i&nbsp;predyspozycje szkoleniowe zaproponowano mi staż instruktorski. Tym samym już od 17 lat zajmuję się
          szkoleniem.
        </p>
        <p>
          Wyszkolenie własnego psa do poziomu IPO2, wieloletnia praca z&nbsp;psami i&nbsp;uczestnictwo
          w&nbsp;szkoleniach , pozwoliło mi na zdobycie wiedzy potrzebnej do uzyskania uprawnień Instruktora Psów
          Użytkowych ZkwP. Każdy kiedyś musi zrobić coś dla siebie i&nbsp;dlatego też po wielu latach szkoleń przyszedł
          czas na......moją własną szkołę, do której serdecznie zapraszam. Synuś to Owczarek Niemiecki Długowłosy. Miał
          na imię Zachar i&nbsp;dziękuję mu za to, że pozwolił mi poznać psi świat. Odszedł w&nbsp;styczniu 2013 roku.
          Do końca darzyliśmy się wzajemnie szacunkiem i&nbsp;prawdziwą miłością. Moim drugim psem była Zula, którą
          znalazłam w&nbsp;lesie i&nbsp;przygarnęłam. Twierdzę, że również była „Owczarkiem Niemieckim” i&nbsp;już.
          Odeszła od nas w&nbsp;czerwcu 2014 roku po ośmiu latach wspólnego życia. 30 maja 2014 pojawiło się
          w&nbsp;naszym domu nowe szczęście REMUS Rottweiler Toruń. Rok później dołączyła do nas Rumba - Markiza de
          Barczes :):), adoptowana z&nbsp;Fundacji Pies Szuka Domu suczka w&nbsp;typie....wszystkich ras :):).
          25.09.2020 przybył do naszego domu Ragnar des Terres de Figaro - Owczarek Belgijski Malinois i...wywrócił
          nasze życie do góry nogami :). Wprowadził mnóstwo radości! Mam nadzieję, że zmienię Wasze życie z&nbsp;psem
          i&nbsp;Waszego psa z&nbsp;Wami tak, abyście darzyli się wzajemną miłością, szacunkiem i&nbsp;zrozumieniem tak
          jak ja ze swoim stadem. Zapraszam na zajęcia:).
        </p>
        <h4>Galeria</h4>
        <AboutMeGallery />
        <h4>Praca z własnymi psami</h4>
        <ul>
          <li>Kurs Ogólnego Posłuszeństwa</li>
          <li>Kurs Posłuszeństwa Wyższego</li>
          <li>Kurs Pracy Węchowej</li>
          <li>Kurs Stróżująco – Obrończy</li>
          <li>Egzamin IPO1</li>
          <li>Egzamin IPO 2</li>
          <li>Warsztaty dla dzieci w zakresie prawidłowego obchodzenia się z psami</li>
          <li>Egzamin Nosework Polska</li>
          <li>Ring Francuski</li>
        </ul>
        <h4>Prowadziłam</h4>
        <ul>
          <li>Psie przedszkole</li>
          <li>Kursy posłuszeństwa</li>
          <li>Kursy pracy węchowej</li>
          <li>Kursy stróżująco - obronne</li>
          <li>Szkolenia indywidualne</li>
          <li>Przygotowania do egzaminów PT</li>
          <li>Przygotowania do ezgaminów IPO</li>
          <li>Przygotowania do egzaminów i zawodów Nosework</li>
          <li>Przygotowania do zawodów Rally - O</li>
          <li>Pokazy szkolenia na wystawach psów rasowych oraz imprezach okolicznościowych</li>
        </ul>
        <h4>Kursy i seminaria</h4>
        <ul>
          <li>05 - 06.12.20 "Projekt Ring" Polska Federacja Ringu Francuskiego Kalisz</li>
          <li>15 - 16.08.20 "Obóz z Ringiem Francuskim" Polska Federacja Ringu Francuskiego Kwidzyn</li>
          <li>04 - 05.01.2020 "Warsztaty z gryzienia/obrony z Tomkiem Rutkowskim" - Gdańsk</li>
          <li>30 - 31.03.2019 "Warsztaty z gryzienia" z Nicolasem Contignon - Bydgoszcz</li>
          <li>10 - 11.11.2018 "Projekt Ring" - Ring Francuski Polska Bydgoszcz</li>
          <li>29 - 30.09.2018 "Projekt Ring" - Ring Francuski Polska Bydgoszcz</li>
          <li>10 - 11.03.2018 Kurs Instruktor Rally - O Gdynia</li>
          <li>12 - 13.11. 2016 Seminarium Nosework z Agnieszką Janarek</li>
          <li>12.12.2008 Seminarium ZkwP oddział Łódź:</li>
        </ul>
        <p>Eliza Barczewska</p>
        <br></br>
      </div>
    </div>
  );
};

export default o_mnie;
