import React from 'react';
import classes from './ZdolnyPiesWeszyciel.module.css';
const ZdolnyPiesWeszyciel = (props) => {
  return (
    <div className={'container'}>
      <div className={classes.ZdolnyPiesWeszycielMain}>
        <h3>Zdolny Pies Węszyciel</h3>
        <p>Kurs przeznaczony dla absolwentów Mądrego Psa – Klasy 2.</p>
        <p>
          Pies w życiu codziennym kieruje się głównie zmysłem węchu. Nie wynaleziono jeszcze na świecie urządzenia,
          komputera, który mógłby się równać w rozpoznawaniu zapachów z psim nosem. Praca węchowa sprawia psu olbrzymią
          przyjemność.{' '}
        </p>
        <p>
          Na kursie ZPW Państwo nauczycie się podstaw pracy węchowej czyli jak ukierunkować tą niezwykłą umiejętność
          psa, a pies nauczy się :
        </p>
        <ul>
          <li>wyszukiwania zapachu Pani/Pana ,</li>
          <li>wybierania przedmiotów przewodnika spośród przedmiotów obcych,</li>
          <li>odnajdywania przedmiotów zagubionych przez właścicieli </li>
        </ul>
        <p>
          W trakcie tego kursu ćwiczenia węchowe przeplatamy nauką pokonywania wybranych przeszkód: tuneli, ruchomych
          kładek, mostka itp.
        </p>
      </div>
    </div>
  );
};

export default ZdolnyPiesWeszyciel;
