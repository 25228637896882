import React from "react";
import classes from "./MainPage.module.css";
import face from "../../../assets/images/eliza_barczewska_2.webp";
import { FaMobileAlt, FaEnvelope } from "react-icons/fa";

const MainPage = props => {
  return (
    <div>
      <div className={`${classes.MainPageMainDiv} `}>
        <div className={classes.topDiv}>
          <div className={classes.mainPageHeading}>
            <h1>
              Łajka <br /> Szkoła dla Psów
            </h1>
          </div>
          <div className={classes.MainPageImage}>
            <img alt={"Eliza-Barczewska-szkolenia-psow-lajka"} src={face} />
            <h5>Eliza Barczewska</h5>
            <h6>Instruktor</h6>
            <div className={classes.pillsDiv}>
              <span>Psów użytkowych</span>
              <span>Rally-Obedience</span>
              <span>Nosework Polska</span>
            </div>
            {/* <ul>
              <li>Psów użytkowych</li>
              <li>Rally-Obedience</li>
              <li>Nosework Polska</li>
            </ul> */}
          </div>
        </div>

        <div className={classes.contactWrapper}>
          <div className={`${classes.mainDivContact} 'container`}>
            <h2>Kontakt</h2>
            <p>
              <strong>pon.&nbsp;-&nbsp;pt. w godz. 10:00 - 16:00</strong>
            </p>
            <p>
              <a href="tel:+48728858389">
                <div className={classes.iconTextDiv}>
                  <FaMobileAlt size={"22px"} /> <span>+48 728 858 389</span>
                </div>
              </a>
            </p>
            <p>
              <a href="mailto:biuro@lajka-szkolenie.pl">
                <div className={classes.iconTextDiv}>
                  <FaEnvelope size={"22px"} />
                  <span>biuro@lajka-szkolenie.pl</span>
                </div>
              </a>
            </p>
            <p>
              Bieżące informacje z życia Szkoły dla Psów Łajka, terminy
              rozpoczęcia nowych kursów - zapraszamy na:
            </p>
            <a
              target="_blank"
              href={"https://pl-pl.facebook.com/%C5%81ajka-150978098401394/"}
              variant={"primary"}
            >
              <strong>Facebook</strong>
            </a>
          </div>

          <div className={`${classes.mainPageDirectionsDiv} 'container`}>
            <h2>Plac szkoleniowy</h2>
            <p>Gdańsk&nbsp;-&nbsp;Morena</p>
            <p>ul.&nbsp;Piekarnicza&nbsp;26</p>
            <p>Brama wjazdowa do Stacji Kontroli Pojazdów</p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2325.339246544907!2d18.58322621587658!3d54.350985980201855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46fd7467df0f6a6d%3A0xb1f09e32e8c0b836!2s%C5%81ajka%20Szkolenie%20Ps%C3%B3w!5e0!3m2!1spl!2spl!4v1613837494309!5m2!1spl!2spl"
              width="350"
              height="350"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              sandbox="allow-scripts allow-same-origin"
              name={"lajka-mapa-dojazdu"}
            />
          </div>

          <div className={classes.mainPagePayments}>
            <h2>Numer konta</h2>
            <p>
              <strong>66 1140 2004 0000 3502 7678 3386</strong>
            </p>
            <p>
              W tytule przelewu prosimy podać: imię psa, nazwisko właściciela,
              rodzaj kursu
            </p>
            <p>
              <strong>Nie obsługujemy płatności kartą</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
