import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CardMedia from '@material-ui/core/CardMedia';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import noseImg from '../../../assets/images/media/nose3.webp';

import Nosework from '../Nosework/Nosework';
import ZdolnyPiesWeszyciel from '../ZdolnyPiesWeszyciel/ZdolnyPiesWeszyciel';

import { GiSniffingDog } from 'react-icons/gi';
import { FaDog } from 'react-icons/fa';
import { MdPets } from 'react-icons/md';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme2 = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#000000b7',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme2}>
        <div className={'container'}>
          <br />
          <CardMedia
            component='img'
            alt='Contemplative Reptile'
            height='300px'
            image={noseImg}
            title='Contemplative Reptile'
          />
          <br></br>
          <h3 style={{ textAlign: 'center' }}>Praca węchowa</h3>
          <br></br>
          <AppBar position='static' color='default'>
            <Tabs
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='on'
              indicatorColor='primary'
              textColor='primary'
              aria-label='scrollable force tabs example'
            >
              <Tab label='Nosework' {...a11yProps(0)} icon={<MdPets />} />
              <Tab label='Zdolny Pies Węszyciel' {...a11yProps(1)} icon={<FaDog />} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Nosework />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ZdolnyPiesWeszyciel />
          </TabPanel>
        </div>
      </MuiThemeProvider>
    </div>
  );
}
