import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import Gallery from 'react-grid-gallery';
import './AboutMeGallery.css';
class Demo4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.images,
    };
  }

  setCustomTags(i) {
    return i.tags.map((t) => {
      return (
        <div key={t.value} style={customTagStyle}>
          {t.title}
        </div>
      );
    });
  }

  render() {
    var images = this.state.images.map((i) => {
      i.customOverlay = (
        <div style={captionStyle}>
          <div>{i.caption}</div>
          {/* {i.hasOwnProperty('tags') && this.setCustomTags(i)} */}
        </div>
      );
      return i;
    });

    return (
      <div
        style={{
          display: 'block',
          minHeight: '1px',
          width: 'auto',
          border: '1px solid #ddd',
          overflow: 'auto',
          textAlign: 'center',
          border: 'none',
        }}
      >
        <Gallery
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          images={images}
          enableImageSelection={false}
        />
        <br></br>
      </div>
    );
  }
}

Demo4.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      thumbnail: PropTypes.string.isRequired,
      srcset: PropTypes.array,
      caption: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      thumbnailWidth: PropTypes.number.isRequired,
      thumbnailHeight: PropTypes.number.isRequired,
    })
  ).isRequired,
};

const captionStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  maxHeight: '240px',
  overflow: 'hidden',
  position: 'absolute',
  bottom: '0',
  width: '100%',
  color: 'white',
  padding: '2px',
  fontSize: '90%',
};

const customTagStyle = {
  wordWrap: 'break-word',
  display: 'inline-block',
  backgroundColor: 'white',
  height: 'auto',
  fontSize: '75%',
  fontWeight: '600',
  lineHeight: '1',
  padding: '.2em .6em .3em',
  borderRadius: '.25em',
  color: 'black',
  verticalAlign: 'baseline',
  margin: '2px',
};

Demo4.defaultProps = {
  images: [
    {
      src: 'https://lajka-szkolenie.pl/gallery/zachar_zula.webp',
      thumbnail: 'https://lajka-szkolenie.pl/gallery/zachar_zula_t.webp',
      thumbnailWidth: 136,
      thumbnailHeight: 150,
      tags: [{ value: 'Zachar i Zula', title: 'Zachar i Zula' }],
      caption: 'Zachar i Zula',
    },
    {
      src: 'https://lajka-szkolenie.pl/gallery/remus.webp',
      thumbnail: 'https://lajka-szkolenie.pl/gallery/remus.webp',
      thumbnailWidth: 200,
      thumbnailHeight: 150,
      tags: [{ value: 'Remus', title: 'Remus' }],
      caption: 'Remus',
    },
    {
      src: 'https://lajka-szkolenie.pl/gallery/rumba.webp',
      thumbnail: 'https://lajka-szkolenie.pl/gallery/rumba.webp',
      thumbnailWidth: 122,
      thumbnailHeight: 150,
      tags: [{ value: 'Rumba', title: 'Rumba' }],
      caption: 'Rumba',
    },
    {
      src: 'https://lajka-szkolenie.pl/gallery/ragnar.webp',
      thumbnail: 'https://lajka-szkolenie.pl/gallery/ragnar_t.webp',
      thumbnailWidth: 131,
      thumbnailHeight: 150,
      tags: [{ value: 'Ragnar', title: 'Ragnar' }],
      caption: 'Ragnar',
    },
  ],
};

export default Demo4;
