import React from "react";
import classes from "./Regulamin.module.css";
const Regulamin = props => {
  return (
    <div className="container">
      <div className={classes.RegulaminMain}>
        <br />
        <h3>
          REGULAMIN UCZESTNICTWA <br /> W KURSACH ORGANIZOWANYCH PRZEZ SZKOŁĘ
          ŁAJKA{" "}
        </h3>
        <ol>
          <li>
            Pies wprowadzony na plac szkoleniowy/salę musi posiadać aktualne
            zaświadczenie o szczepieniu na wściekliznę ( nie dotyczy szczeniąt
            poniżej 5 miesiąca życia ).
          </li>
          <li>Na zajęcia przychodzimy z psem na smyczy.</li>
          <li>
            Zwolnienie psa ze smyczy następuje tylko za zgodą instruktora.
          </li>
          <li>Piesek przychodzi na zajęcia po spacerku.</li>
          <li>Każdy właściciel sprząta po swoim piesku (kupka).</li>
          <li>
            Ze względu na bezpieczeństwo osób przebywających na placu
            szkoleniowym/sali oraz komfort psychiczny psów nie dotykamy psów bez
            zgody właściciela.
          </li>
          <li>Szanując czas innych nie spóźniamy się na zajęcia.</li>
          <li>
            Osoby, które chciałyby wejść na plac szkoleniowy w trakcie trwania
            zajęć proszone są o oczekiwanie przed wejściem na zgodę instruktora.
          </li>
          <li>
            Dopóki się nie poznamy zachowujemy między psami bezpieczną
            odległość. Nie każdy piesek jest towarzyski i lubi się
            zaprzyjaźniać.
          </li>
          <li>
            Na zajęcia przychodzimy ubrani wygodnie. Liczymy się z ubrudzeniem,
            a nawet uszkodzeniem stroju – uczy się Pan/Pani i pies- każdy ma
            prawo popełniać błędy.
          </li>
          <li>Z psem ćwiczą osoby dorosłe.</li>
          <li>
            W trosce o bezpieczeństwo psów i osób przebywających na placu
            prosimy NIE WCHODZIĆ na tor przeszkód bez zgody instruktora.
          </li>
          <li>
            W przypadku nieobecności na zajęciach przewodnika z psem odrobienie
            zajęć jest możliwe tylko w trybie indywidualnym, w trakcie trwania
            kursu. Koszt 70 zł/ 1 lekcja.
          </li>
          <li>Za niewykorzystane lekcje ZWROT KOSZTÓW NIE PRZYSŁUGUJE.</li>
          <li>
            Z powodu pandemii, której wynikiem jest znaczne ograniczenie ilości
            miejsc nie ma możliwości zwrotu opłaty za kurs lub przeniesienia na
            kurs następny w przypadku rezygnacji, choroby psa lub właściciela,
            kwarantanny i innych. Prosimy o przemyślane decyzje.
          </li>
          <li>
            Gwarancją miejsca na kursie jest dokonanie rezerwacji oraz opłaty
            zadatku w wysokości 200 zł na konto: ŁAJKA Eliza Barczewska NR
            KONTA: 66 1140 2004 0000 3502 7678 3386 w terminie 7 dni od dnia
            rezerwacji.
          </li>
          <li>W przypadku rezygnacji z kursu zadatek NIE PODLEGA ZWROTOWI.</li>
          <li>
            Warunkiem rozpoczęcia kursu jest dokonanie pełnej opłaty za kurs do
            drugiej lekcji.
          </li>
          <li>
            Obowiązuje zakaz fotografowania i filmowania zajęć grupowych ( RODO
            )
          </li>
          <li>
            Właściciel psa ponosi pełną odpowiedzialność prawną z tytułu
            własnych działań i zaniechań, w wyniku których wystąpiła szkoda na
            osobie lub mieniu innych uczestników szkolenia bądź osób trzecich.
          </li>
          <li>
            Zapisanie się na kurs oznacza akceptację niniejszego REGULAMINU.
          </li>
        </ol>
        <br />
      </div>
    </div>
  );
};

export default Regulamin;
