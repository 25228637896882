import React from 'react';
import logo from '../../assets/images/logo.webp';
import classes from './Banner.module.css';
import { Image } from 'react-bootstrap';
const Banner = (props) => {
  return (
    <div className={`${classes.bannerDiv}`}>
      <Image src={logo} alt='łajka-logo' />
    </div>
  );
};

export default Banner;
