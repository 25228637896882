import React from 'react';
import classes from './Behawiorysta.module.css';
import rallyImg from '../../../assets/images/media/spotkanie_z_behawiorysta.webp';
import CardMedia from '@material-ui/core/CardMedia';
const Behawiorysta = (props) => {
  return (
    <div className='container'>
      <div className={classes.rallyOMain}>
        <br />
        <CardMedia
          component='img'
          alt='Contemplative Reptile'
          height='300px'
          image={rallyImg}
          title='Contemplative Reptile'
          style={{ objectPosition: 'top' }}
        />

        <br />
        <h3>Spotkanie z behawiorystą</h3>
        <p>
          Wychowanie psa to nie tylko nauka poleceń, ale przede wszystkim prawidłowe postępowanie z&nbsp;psem na co
          dzień, w&nbsp;domu, na spacerze. W&nbsp;ramach spotkania dowiesz się jakie zasady powinny obowiązywać
          w&nbsp;Waszym domu, jak radzić sobie z&nbsp;psio – ludzkimi problemami, jak wyeliminować zachowania
          niepożądane.
        </p>
        <p>
          Wszyscy instruktorzy pracujący w&nbsp;naszej szkole są certyfikowanymi behawiorystami zwierząt, ponieważ,
          naszym zdaniem, nie można być dobrym instruktorem nie będąc behawiorystą i&nbsp;odwrotnie :).
        </p>

        <p>Spotkania z&nbsp;behawiorystą odbywają się na naszym placu szkoleniowym.</p>
        <p>Koszt: 150 zł/h</p>
      </div>
    </div>
  );
};

export default Behawiorysta;
