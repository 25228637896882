import React from 'react';

const MadryPies = (props) => {
  return (
    <div>
      <h3>Klasa 2 - Mądry Pies</h3>
      <p>
        Kurs „Mądry Pies” jest kontynuacją „ Grzecznego Psa”. Jeśli chcecie Państwo, aby Wasz pupil potrafił więcej niż
        każdy dobrze wychowany pies, jeśli macie satysfakcję z nabywania nowych umiejętności przez Waszego czworonoga
        zapraszamy do klasy II.
      </p>
      <p>Na tym kursie podnosimy stopień trudności poleceń, które pies opanował w klasie I.</p>
      <p>Zwiększamy czas trwania i odległość wykonywanych poleceń, ale przede wszystkim uczymy psa:</p>
      <ul>
        <li>chodzenia bez smyczy </li>
        <li>komend optycznych siad, waruj, stój, noga </li>
        <li>komend głosowych z odległości</li>
        <li>polecenia SIAD,WARUJ i STÓJ z MARSZU </li>
      </ul>
      <p>Zajęcia 2 razy w tygodniu w dni i godziny wybrane przy wcześniejszych zapisach.</p>
    </div>
  );
};

export default MadryPies;
