import React from "react";
import classes from "./PsiePrzedszkole.module.css";
import terrier from "../../../assets/images/media/przedszkole.webp";
import CardMedia from "@material-ui/core/CardMedia";
const PsiePrzedszkole = (props) => {
  return (
    <div className="container">
      <div className={classes.PsiePrzedszkoleMain}>
        <br />
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          height="300px"
          image={terrier}
          title="Contemplative Reptile"
        />
        <br />
        <h3>Psie Przedszkole</h3>
        <p>
          Do Psiego Przedszkola zapraszamy szczeniaczki od 2 - 6 miesiąca życia.
        </p>
        <p>Dlaczego warto do nas przyjść?</p>
        <p>Szczeniaczek:</p>
        <ul>
          <li>
            będzie mógł pobawić się z kolegami w bezpiecznych dla niego
            warunkach
          </li>
          <li>nauczy się jak żyć wśród rówieśników</li>
          <li>
            będzie się uczył najważniejszych poleceń: SIAD, STÓJ, DO MNIE ( w
            towarzystwie to nie jest proste)
          </li>
          <li>
            będzie pokonywał dostosowane do jego wieku i wzrostu pierwsze
            przeszkody,
          </li>
        </ul>
        <p>
          a Państwo spędzicie miło czas obserwując jak milusiński poznaje świat,
          radzi sobie z niespodziankami przygotowanymi przez nas i nawiązuje
          pierwsze przyjaźnie. Dacie psu możliwość oswojenia się z bodźcami, z
          którymi będzie się spotykał z dorosłym życiu.
        </p>
        <p>
          Ponad to odpowiemy na wszystkie Państwa pytania dotyczące wychowania,
          socjalizacji i pielęgnacji szczeniaka.
        </p>

        <ul>
          <li>Kameralne grupy do 5 szczeniąt.</li>
          <li>
            Zajęcia odbywają się na placu szkoleniowym lub w razie złej pogody -
            na salce treningowej tuż obok placu.
          </li>
          <li>
            <u>Obowiązuje rezerwacja miejsc.</u>
          </li>
        </ul>
        {/* <p>
          Treningi odbywają się w każdą niedzielę o godz. 13:00. Nie musisz się
          zapisywać, nasi Instruktorzy będą na Ciebie czekać. Po prostu
          zabieramy szczeniaka, smakołyki dla niego i przychodzimy na zajęcia.
        </p> */}
        {/* <p>
          Karnet na 4 wejścia/200 zł ( do wykorzystania w ciągu 2 miesięcy ) lub
          wejściówka jednorazowa 60 zł.
        </p> */}
        {/* <p>Treningi dla maluchów są podzielone na trzy grupy:</p>
        <ul>
          <li>
            <b>ZERÓWKA</b> - dla szczeniąt, które przychodzą do szkoły po raz pierwszy - nauka polecenia siad, chodzenia
            na smyczy i komendy przywoławczej przeplatana zabawą z kolegami z grupy :)
          </li>
          <li>
            <b>STARSZAKI</b> - dla absolwentów Zerówki - nauka polecenia stój, trening medyczny, tor przeszkód dla
            szczeniąt ( tunele, slalomy, ruchome kładki, cavaletti )
          </li>
          <li>
            <b>JUNIORKI</b> - dla absolwentów Starszaków - utrwalenie umiejętności nabywanych w Zerówce i w Starszakach
          </li>
        </ul>
        <h4>Gwarancją miejsca jest uiszczenie opłaty w ciągu 7 dni od daty rezerwacji.</h4>
        <h4>W przypadku rezygnacji z kursu lub nieobecności na zajęciach zwrot pieniążków nie przysługuje.</h4> */}
        <br />
      </div>
    </div>
  );
};

export default PsiePrzedszkole;
