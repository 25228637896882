import React from 'react';

const BystryPies = (props) => {
  return (
    <div>
      <h3>Klasa 4 - Bystry Pies</h3>
      <p>Kurs przeznaczony dla absolwentów Klasy 3.</p>
      <p>W programie kursu:</p>
      <ul>
        <li>aport z ziemi</li>
        <li>skok przez przeszkodę</li>
        <li>aport przez przeszkodę</li>
        <li>komendy w biegu</li>
        <li>zatrzymanie psa z biegu</li>
        <li>komendy optyczne 20 – 25 kroków</li>
        <li>zostawanie bez widoczności przewodnika</li>
        <li>wysyłanie na przód</li>
      </ul>
    </div>
  );
};

export default BystryPies;
