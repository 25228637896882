import React from 'react';

const GrzecznyPies = (props) => {
  return (
    <div>
      <h3>Klasa 1 - Grzeczny Pies</h3>
      <p>
        Jeśli chcesz, aby Twój pies żył z Tobą szczęśliwie, żeby Cię rozumiał, szanował niezbędny jest kurs
        posłuszeństwa, na którym Twój pies będzie uczył się:
      </p>
      <ul>
        <li>chodzenia przy nodze, na luźnej smyczy, w różnych tempach</li>
        <li>polecenia SIAD</li>
        <li>polecenia STÓJ</li>
        <li>polecenia WARUJ</li>
        <li>polecenia DO MNIE</li>
        <li>polecenia NOGA</li>
        <li>polecenia ZOSTAŃ w każdej sytuacji</li>
        <li>nie reagowania bez pozwolenia na innych ludzi, psy, zabawki</li>
      </ul>
      {/* <p>
        Wychowanie psa to nie tylko nauka poleceń, ale przede wszystkim prawidłowe postępowanie z psem na co dzień, w
        domu, na spacerze. W ramach kursu otrzymujecie Państwo materiały, z których dowiecie się:{' '}
      </p>
      <ul>
        <li>po co są polecenia, których będzie uczył się Twój pies i jak je wykorzystać</li>
        <li>jak prawidłowo wychodzić z psem na spacer</li>
        <li> jak zostawiać go samego w domu, żeby nie niszczył przedmiotów, nie hałasował</li>
        <li>w jaki sposób podawać mu jedzenie</li>
        <li>w co się z nim bawić</li>
      </ul> */}
      {/* <p>
      Kurs to 12 spotkań (1 spotkanie organizacyjne ( bez psów), 11 zajęć praktycznych trwających do 50 min. ( z psami )
      </p> */}
      <p>
      Kurs to 10 spotkań ( 1 spotkanie teoretyczne ( bez psów ), 9 zajęć praktycznych z psami trwających do 50 min.).
      </p>
      <p>Zajęcia odbywają się 2 razy w tygodniu w dni i godziny wybrane przy wcześniejszych zapisach.</p>
    </div>
  );
};

export default GrzecznyPies;
