import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './Posluszenstwo.css';
import CardMedia from '@material-ui/core/CardMedia';
import PosluszenstwoImage from '../../../assets/images/media/posluszenstwo.webp';

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import LooksOneRoundedIcon from '@material-ui/icons/LooksOneRounded';
import LooksTwoRoundedIcon from '@material-ui/icons/LooksTwoRounded';
import Looks3RoundedIcon from '@material-ui/icons/Looks3Rounded';
import Looks4RoundedIcon from '@material-ui/icons/Looks4Rounded';
import Looks5RoundedIcon from '@material-ui/icons/Looks5Rounded';

import GrzecznyPies from '../GrzecznyPies/GrzecznyPies';
import MadryPies from '../MadryPies/MadryPies';
import ZdolnyPies from '../ZdolnyPies/ZdolnyPies';
import BystryPies from '../BystryPies/BystryPies';
import SuperPies from '../SuperPies/SuperPies';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonForce() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme2 = createMuiTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#000000b7',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#11cb5f',
      },
    },
  });

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={theme2}>
        <div className='container'>
          <br />
          <CardMedia
            component='img'
            alt='Contemplative Reptile'
            height='300px'
            image={PosluszenstwoImage}
            title='Contemplative Reptile'
          />
          <br />
          <h3 style={{ textAlign: 'center' }}>Posłuszeństwo</h3>

          <AppBar position='static' color='default'>
            <Tabs
              theme={theme2}
              value={value}
              onChange={handleChange}
              variant='scrollable'
              scrollButtons='on'
              indicatorColor='primary'
              textColor='primary'
              aria-label='scrollable force tabs example'
            >
              <Tab label='Klasa 1 - Grzeczny Pies' icon={<LooksOneRoundedIcon />} {...a11yProps(0)} />
              <Tab label='Klasa 2 - Mądry Pies' icon={<LooksTwoRoundedIcon />} {...a11yProps(1)} />
              <Tab label='Klasa 3 - Zdolny Pies' icon={<Looks3RoundedIcon />} {...a11yProps(2)} />
              <Tab label='Klasa 4 - Bystry Pies' icon={<Looks4RoundedIcon />} {...a11yProps(3)} />
              <Tab label='Klasa 5 - Super Pies' icon={<Looks5RoundedIcon />} {...a11yProps(4)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <GrzecznyPies />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <MadryPies />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ZdolnyPies />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <BystryPies />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <SuperPies />
          </TabPanel>
        </div>
      </MuiThemeProvider>
    </div>
  );
}
